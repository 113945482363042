if (getCookie('msbrcity') == '') {
    $('#choosesite').modal();
}
var ch_lr = document.getElementById("choselittlerock")
ch_lr.onclick = function(e) {
    var url = e.target.href;
    e.preventDefault();
    var city = 'littlerock'
    // console.log('attempting to set city cookie to: ' + city)
    setCookie('msbrcity', city, 100);
    // var result = getCookie('msbrcity')
    // console.log('the RETRIEVED value from the cookie is: ' + result)
    // var failtest = getCookie('doenotexist')
    // if(failtest == '')
    // {
    //     console.log('empty cookie ok')
    // }
    window.open(url, "_self");
}
var ch_m = document.getElementById("chosememphis")
ch_m.onclick = function(e) {
    var url = e.target.href;
    e.preventDefault();
    // alert('Memphis');
    var city = 'memphis'
    // console.log('attempting to set city cookie to: ' + city)
    setCookie('msbrcity', city, 100);
    window.open(url, "_self");
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}